<template>
    <div class="settings">
        <SecurityControls />
    </div>
</template>

<script>
import SecurityControls from '@/components/settings/securityControls/index.vue';

export default {
    name: 'SettingsSecurityControls',
    components: {
        SecurityControls,
    },
};
</script>
